import { FC } from 'react';
import { landingVideos } from '~/common/constants/video';

import styled from 'styled-components';

import useMediaQuery from '~/common/hooks/useMediaQuery';
import { useIsMounted } from '~/common/hooks/useIsMounted';
import { Image } from '~/components/wrappers/Image';
import { NavigationItem } from '~/layout/styles';
import { Routes } from '~/common/constants/routes';
import { useNavigation } from '~/common/hooks/useNavigation';

export const HomeHero: FC = () => {
  const { isMobile } = useMediaQuery();
  const { isMounted } = useIsMounted();
  const { goToShop } = useNavigation();

  return (
    <Section>
      <VideoThumbnail
        src="https://cdn.shopify.com/s/files/1/0449/1801/4109/files/thumbnailBP.png?v=1721826409"
        alt="video thumbnail"
      />

      {isMounted && (
        <BackgroundVideo loop autoPlay playsInline muted>
          <source
            src={isMobile ? landingVideos.mobile.mp4 : landingVideos.mp4}
            type="video/mp4"
          />
        </BackgroundVideo>
      )}
      <StyledNavigationItem to={Routes.Shop} onClick={goToShop}>
        <p>shop</p>
      </StyledNavigationItem>
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: absolute;
  width: 100%;
  top: 0;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.black};
`;

const VideoThumbnail = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: blur(4px);
  /* To make up for safari filter blur issue */
  transform: translate3d(0, 0, 0);
`;

const BackgroundVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;

  overflow: hidden;
  object-fit: cover;
`;

const StyledNavigationItem = styled(NavigationItem)`
  z-index: ${({ theme }) => theme.zIndex.headingImage};
  position: absolute;
  bottom: 20%;
  color: ${({ theme }) => theme.colors.buttonText};
  min-width: 13.375rem;

  p {
    font-size: 2.875rem;
    line-height: 4.6319rem;
    font-weight: 700;
    font-style: italic;
    text-align: center;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    min-width: 10.5rem;
    p {
      font-size: 2.25rem;
    }
  }
`;
